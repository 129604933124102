import React from "react";

const LazyPages = {
  LoginPage: React.lazy(() => import("../pages/LoginPage/LoginPage")),
  LayoutProtected: React.lazy(() => import("../layouts/LayoutProtected")),
  DashboardPage: React.lazy(
    () => import("../pages/DashboardPage/DashboardPage")
  ),
  SettingsPage: React.lazy(() => import("../pages/SettingsPage/Settingspage")),
  PrivateOffice: React.lazy(() => import("../pages/TableOffice/Tableoffice")),
  ProfessionalUser: React.lazy(
    () => import("../pages/ProfessionalTable/ProfessionalTable")
  ),
  JobView: React.lazy(() => import("../pages/JobView/JobView")),
  Category: React.lazy(
    () => import("../pages/CategoryManagement/CategoryManagement")
  ),
  Notification: React.lazy(
    () => import("../pages/NotificationManagement/NotificationManagement")
  ),
  PayRangeAndBills: React.lazy(
    () => import("../pages/PayRangeAndBills/PayRangeAndBills")
  ),
  Jobs: React.lazy(() => import("../pages/Jobs/Jobs")),
};

export default LazyPages;

import React, { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { AuthValue } from "src/models/layout.models";
import { LoginProps } from "src/models/user.model";

import { CONSTANTS } from "../constants";
import { useLocalStorage } from "../hooks/useLocalStorage";
export const UserContext = createContext<AuthValue | null>(null);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useLocalStorage<LoginProps | null>(
    CONSTANTS.LOCAL_STORAGE.PROFILE,
    null
  );
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = async (data: LoginProps, redirect = true): Promise<void> => {
    setUser(data);
    redirect && navigate("/admin/dashboard");
  };

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null);
    localStorage.clear();
    navigate("/", { replace: true });
  };

  const value = useMemo<AuthValue>(
    () => ({
      user,
      login,
      logout,
    }),
    [user]
  );
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useAuth = () => {
  return useContext(UserContext);
};

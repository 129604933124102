import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { COMMON_CONSTANTS } from "src/constants/commonConstants";

import { AuthProvider } from "../context/userContext";

import LazyPages from "./LazyPages";

const MainRoute: React.FC = () => {
  return (
    <>
      <ToastContainer />
      <AuthProvider>
        <Suspense fallback={COMMON_CONSTANTS.LAZY_LOADING}>
          <Routes>
            <Route path="/" element={<LazyPages.LoginPage />} />
            <Route path="/login" element={<LazyPages.LoginPage />} />
            <Route path="/admin" element={<LazyPages.LayoutProtected />}>
              <Route path="dashboard" element={<LazyPages.DashboardPage />} />
              <Route path="settings" element={<LazyPages.SettingsPage />} />
              <Route
                path="office-users"
                element={<LazyPages.PrivateOffice />}
              />
              <Route
                path="professional-users"
                element={<LazyPages.ProfessionalUser />}
              />
              <Route path="job-view" element={<LazyPages.JobView />} />
              <Route path="category" element={<LazyPages.Category />} />
              <Route path="notification" element={<LazyPages.Notification />} />
              <Route
                path="pay-range-bill-rate"
                element={<LazyPages.PayRangeAndBills />}
              />
              <Route path="jobs" element={<LazyPages.Jobs />} />
            </Route>
          </Routes>
        </Suspense>
      </AuthProvider>
    </>
  );
};

export default MainRoute;

export const CONSTANTS = {
  API: {
    AUTH: "Authorization",
    BEARER: "Bearer ",
    TIMEOUT: 100000,
  },
  ARRAY_DEFAULT_VALUE: 0,
  CLOSE_ICON: "x",
  COMMON_CONSTANTS: {
    CLOSE: false,
    EMPTY_SPACE: " ",
    EMPTY_VALUE: "",
    HIDE: false,
    NO: false,
    OPEN: true,
    SHOW: true,
    START_LOADER: true,
    STOP_LOADER: false,
    YES: true,
  },
  DEFAULT_VALUE: 0,
  FIRST_TEN_ITEM: 10,
  HTTP_STATUS: {
    BAD_REQUEST: 400,
    ERROR: "error",
    FORBIDDEN: 403,
    INTERNAL_SERVER_ERROR: 500,
    INVALID_DATA: 422,
    NOT_FOUND: 404,
    SUCCESS: 200,
    UNAUTHORIZED: 401,
  },
  INITIAL_VALUE: 0,
  LEVEL_UNIT: 1,
  LOCAL_TYPE: {
    LOCAL_STORAGE: "JSON",
  },

  LOCAL_STORAGE: {
    ACCESS_TOKEN: "ACCESS_TOKEN",
    PROFILE: "PROFILE",
    REFRESH_TOKEN: "REFRESH_TOKEN",
  },
  MODAL: {
    LG: "lg",
    MD: "md",
    SM: "sm",
    XL: "xl",
  },
  NOT_FOUND_VALUE: -1,
  NOT_VALUE: null,
  NOTIFICATION: {
    STATUS: {
      STATUS_APPROVE: 1,
      STATUS_NEW_JOB: 5,
      STATUS_PENDING: 2,
      STATUS_REJECT: 3,
      STATUS_RELEASE: 4,
    },
    TYPE: {
      NOTIFICATION_CONTRACT: 2,
      NOTIFICATION_JOB: 1,
      NOTIFICATION_OFFICE_PROFILE: 3,
      NOTIFICATION_PROFESSIONAL_PROFILE: 4,
    },
  },
  POSITION: {
    RIGHT: "right",
  },
  STATUS: {
    FAILED: "failed",
    SUCCESS: "success",
  },
  TOAST_TYPE: {
    AUTOCLOSE: 1000,
    ERROR: "error",
    INFO: "info",
    SUCCESS: "success",
    TIMER: 3000,
    WARNING: "warning",
  },
  USER_TYPE: {
    OFFICE: {
      KEY: 1,
      NAME: "office",
    },
    PROFESSIONAL: {
      KEY: 2,
      NAME: "professional",
    },
    ADMIN: {
      KEY: 3,
      NAME: "admin",
    },
  },
};

import React from "react";
import { BrowserRouter } from "react-router-dom";

import MainRoute from "./routes/MainRoute";

import "@coreui/coreui/dist/css/coreui.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import "react-datepicker/dist/react-datepicker.css";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
const App: React.FC = () => {
  return (
    <BrowserRouter>
      <MainRoute />
    </BrowserRouter>
  );
};

export default App;

export const COMMON_CONSTANTS = {
  APPR_TEMPLATE: "T",
  BUTTON_ADD_NEW: " Add New",
  BUTTON_ADD: "Add",
  BUTTON_DELETE: "Delete",
  BUTTON_DISABLE: "Disable",
  BUTTON_EDIT: "Edit",
  BUTTON_ENABLE: "Enable",
  BUTTON_NO: "No",
  BUTTON_YES: "Yes",
  CANCEL_BUTTON: "Cancel",
  CATEGORY: {
    CATEGORY_LABEL: {
      JOB_CATEGORY: "Manage Job Category",
      JOB_SKILL: "Manage Job Skills",
      MANAGE_SOFTWARE: "Manage Software",
      SPECIALITY_SKILL: "Speciality Skills",
    },

    MANAGE_SOFTWARE: {
      ADD_MESSAGE: "The Software(s) added successfully.",
      DELETE_MESSAGE: "The Software  deleted successfully.",
      DISABLE_MESSAGE: "The Software disabled successfully.",
      DUPLICATE_MESSAGE: "Software with this name already exists.",
      EDIT_MESSAGE: "The Software updated successfully.",
      ENABLE_MESSAGE: "The Software enabled successfully.",
      NO_CHANGE: "No changes added to software.",
      REQUIRED: "Name of the software is required.",
    },

    SPECIALITY_SKILLS: {
      ADD_MESSAGE: "The Speciality skill(s) added successfully.",
      DELETE_MESSAGE: "The Speciality skill  deleted successfully.",
      DISABLE_MESSAGE: "The Speciality skill disabled successfully.",
      DUPLICATE_MESSAGE: "Speciality skill with this name already exists.",
      EDIT_MESSAGE: "The Speciality skill updated successfully.",
      ENABLE_MESSAGE: "The Speciality skill enabled successfully.",
      NO_CHANGE: "No changes added to speciality skill.",
      REQUIRED: "Name of the speciality skill is required.",
    },
  },
  COMMON_DATA_FIRST_INDEX: 0,
  COMMON_DATA_SECOND_INDEX: 1,
  CONFIRM_BUTTON: "Confirm",
  CONFIRM_DELETE_MESSAGE: "Are you sure want to delete this item",
  CONTENT: "content",
  DANGER_COLOR: "danger",
  DATA_TYPE: {
    OBJECT: "object",
    STRING: "string",
  },
  DATE: "date",
  DEFAULT_ACTION_STATE: false,
  DOUBLE_QUOTES_WITH_COMMA: ",",
  DOUBLE_QUOTES: "",
  DSO: "DSO",

  EMAIL_REGEX_VALIDATE_EXPRESSION:
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/,
  EMPTY: "",
  FALSE_STATE_VARIABLE: false,
  FIELD_TYPE: {
    SELECT: "select",
  },
  HALF_DAY_HOUR: 12,
  HIDE: false,
  INITIAL: "0",
  INPUT_VALUE_LENGTH: 0,
  JOB_CATEGORIES: "job_categories",
  JOB: {
    DEFAULT_EMPTY: "",
    DEFAULT_PAGINATION_LIMIT_ARRAY: [25, 50, 75],
    DEFAULT_TIMEOUT: 1000,
    FIELDS: {
      JOB_ID: "job_id",
      CATEGORY: "category",
      JOB_TYPE: "day_type",
      OFFICE: "office",
      STATE: "state",
      STATUS: "status",
      ACTION: "action",
    },
    FIELD_TYPE: {
      TEXT: "text",
    },
    FILTER: {
      NAME: "name",
      SEARCH: "search",
      SORT: "sort",
      TYPE: {
        CITY: "cityUuid",
        DATE: "dateUuid",
        RANGE: "range",
        SELECT: "select",
      },
    },
    FIND_FILTER: {
      APPLICANT_STATUS: "status",
      FIND_PROFESSIONALS: "Find professionals with in the range",
      CITY: "city",
      DATE: "date",
      NAME: "name",
      RANGE: "range",
      ZIP: "zip",
    },
    JOB_TYPES: {
      0: {
        KEY: "directHire",
        TYPE: "primary",
        VALUE: 0,
      },
      1: {
        KEY: "singleDay",
        TYPE: "primary",
        VALUE: 1,
      },
      2: {
        KEY: "multiDay",
        TYPE: "secondary",
        VALUE: 2,
      },
    },
    JOB_STATUS: {
      1: {
        KEY: "open",
        TYPE: "secondary",
        VALUE: 1,
      },
      2: {
        KEY: "published",
        TYPE: "success",
        VALUE: 2,
      },
      3: {
        KEY: "hired",
        TYPE: "success",
        VALUE: 3,
      },
      4: {
        KEY: "completed",
        TYPE: "success",
        VALUE: 4,
      },
      5: {
        KEY: "cancelled",
        TYPE: "secondary",
        VALUE: 5,
      },
      6: {
        KEY: "rejected",
        TYPE: "secondary",
        VALUE: 6,
      },
    },

    MENU: { EDIT: "edit", PUBLISH: "Publish", REJECT: "Reject", VIEW: "View" },
    PAYLOAD: {
      DEFAULT_LIMIT: 25,
      DEFAULT_PAGE: 1,
      DEFAULT_SORT: "DESC",
    },
    PLACEMENT_TYPE: {
      CONTRACT_HIRE: 1,
      DIRECT_HIRE: 2,
    },
    SORT: "sort_by",
    STATUS: {
      OPEN: 1,
      REJECT: 6,
      PUBLISH: 2,
    },
  },
  KEYWORD_STRING: "string",
  KEYWORD: "Enter",
  LABEL_NAME: "username",
  LAZY_LOADING: "loading..",
  LEAD_ZERO: "0",
  LENGTH_OF_COLUMN_DEF: 0,
  LENGTH_OF_FILTERED_DATA: 0,
  LICENCE: "licenses",
  LOADING_STATE_FALSE: false,
  LOADING_STATE_TRUE: true,
  LOCAL_STORAGE_ACCESS_TOKEN: "ACCESS_TOKEN",
  LOGIN_FORM_LOGO: "/assets/images/dds-start-logo.png",
  LOGIN_FAIL_UNAUTHORIZED: "Invalid Email/ Password. Please try again.",
  LOGOUT_MESSAGE: "You have been successfully logged out",
  LOGOUT_SUCCESS: "success",
  MODAL_STATE_FALSE: false,
  MODAL_STATE_TRUE: true,
  OFFICE_TYPE_ONE: 1,
  OFFICE_TYPE_TWO: 2,
  OLD_PASSWORD_ERROR: "The old password provided does not match.",
  PAGE_CONSTANTS: [10, 20, 50, 100],
  PAGINATION: {
    ICON_INNER_SIZE: 15,
    ICON_WRAP_SIZE: 21,
    START_PAGE: 1,
    PAGE_INTERVAL: 1,
  },
  PAGINATION_DEFAULT_PAGE: 1,
  PAGINATION_DEFAULT_LIMIT: 25,
  PAGINATION_DEFAULT_VALUE: 0,
  PAGINATION_TYPE_FROM: "from",
  PAGINATION_TYPE_TO: "to",
  PAGINATION_PAGE: "page",
  PAGINATION_LIMIT: "limit",
  PASSWORD_ERROR_MESSAGE: "Failed to change the password! Please try again",
  PASSWORD_NOT_MATCH: "The New Password and Confirm password do not match",
  PASSWORD_SUCCESS_MESSAGE: "Your password has been changed successfully",
  PASSWORD_VALIDATION:
    "Must contain a minimum of 8 characters, 1 uppercase letter, 1 numeric digit & 1 special character.",
  PRIVATE: "Private",
  PROFESSIONAL_CONTRACTS: "professional_contracts",
  RESPONSE_TIME_OUT: 2000,
  SHOW: true,
  SIDEBAR_LOGO: "/assets/images/dds-start-logo.png",
  SINGLE_MONTH: 1,
  SKILLS_LENGTH_CHECK: 0,
  SPACE: " ",
  STATUS_FALSE: false,
  STATUS_TRUE: true,

  SUBMIT_BUTTON: "Submit",
  TAB_INDEX_FIRST: 0,
  TABLE_DEFAULT_FLEX: 1,
  TABLE_FILTER_DEFAULT: false,
  TABLE_NO_DETAILS: "No results found.",
  TEN_MIN: 10,
  TITLE: "title",
  TOAST_SUCCESS: "success",
  TODAY: "Today",
  TRUE_STATE_VARIABLE: true,
  TWO_DIGIT_DATA: 2,
  VALUE_TWO: 2,
  VARIABLE_BOOLEAN: "boolean",
  VARIABLE_NEGATIVE_ONE: -1,
  VARIABLE_ONE: 1,
  VARIABLE_SEARCH: "Search",
  VARIABLE_ZERO: 0,
  VIEW_STATE_FALSE: false,
  VIEW_STATE_TRUE: true,
};
